const loginFields = [
  {
    labelText: "Email address",
    labelFor: "email-address",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email address",
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "current-password",
    isRequired: true,
    placeholder: "Password",
  },
];

const signupFields = [
  {
    labelText: "Username",
    labelFor: "username",
    id: "fullName",
    name: "username",
    type: "text",
    autoComplete: "username",
    isRequired: true,
    placeholder: "Full Name",
  },
  {
    labelText: "Mobile Number",
    labelFor: "mobileNumber",
    id: "phone",
    name: "phone ",
    type: "tel",
    autoComplete: "tel",
    isRequired: true,
    placeholder: "Phone Number",
  },
  {
    labelText: "Email address",
    labelFor: "email-address",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email address",
  },

  {
    labelText: "Password",
    labelFor: "password",
    id: "password",
    name: "password",
    type: "password",
    autoComplete: "current-password",
    isRequired: true,
    placeholder: "Password",
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "passwordagain",
    name: "passwordagain",
    type: "password",
    autoComplete: "current-password",
    isRequired: true,
    placeholder: "Password again",
  },
];
const ForgotPasswordFields = [
  {
    labelText: "Email address",
    labelFor: "email-address",
    id: "email",
    name: "email",
    type: "email",
    autoComplete: "email",
    isRequired: true,
    placeholder: "Email address",
  },
  // {
  //   labelText: "Password",
  //   labelFor: "password",
  //   id: "oldPassword",
  //   name: "oldPassword",
  //   type: "password",
  //   autoComplete: "current-password",
  //   isRequired: true,
  //   placeholder: "Old Password",
  // },
  // {
  //   labelText: "Password",
  //   labelFor: "password",
  //   id: "newPassword",
  //   name: "newPassword",
  //   type: "password",
  //   autoComplete: "current-password",
  //   isRequired: true,
  //   placeholder: "New Password",
  // },
];

const UpdatePasswordFields = [
  {
    labelText: "Password",
    labelFor: "password",
    id: "newPassword",
    name: "newPassword",
    type: "password",
    autoComplete: "current-password",
    isRequired: true,
    placeholder: "New Password",
  },
  {
    labelText: "Password",
    labelFor: "password",
    id: "confirmPassword",
    name: "confirmPassword",
    type: "password",
    autoComplete: "current-password",
    isRequired: true,
    placeholder: "Confirm Password",
  },
];
export {
  loginFields,
  signupFields,
  ForgotPasswordFields,
  UpdatePasswordFields,
};
